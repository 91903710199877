
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import MultipleBarcodeScanMixin from '@/components/mixins/MultipleBarcodeScanMixin.vue'
import ScanArea from '@/components/svg/ScanArea.vue'
import { FETCH_BARCODE, SAVE_SCANNED_BARCODES, UNPAIRING } from '@/store/actions'
import { unpairBarcode } from '@/utils/api'
import { IBarcode, IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
@Options({
  components: {
    ScanArea,
    BarcodeLabelList,
    BarcodeSymbologiesSelection,
  },
  emits: [],
  name: 'BarcodeUnpairScan',
})
export default class BarcodeUnpairScan extends Mixins(MultipleBarcodeScanMixin) {
  loading = false
  dialogVisible = false
  isReadyToScan = true

  async created() {
    this.loading = true
    await this.getBarcodeInfo()
    this.loading = false
  }

  async getBarcodeInfo() {
    if (this.$route.params?.barcodeId && this.$route.params?.project) {
      await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params?.barcodeId })
    }
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  barcodeTypeInfo(barcodeType: string): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project?.details?.barcodeTypes[barcodeType]
  }

  async validateBarcode(resultScanned: IBarcode) {
    if (this.barcode.id === resultScanned.id) throw this.$t('barcode_unpairing_by_itself_error')

    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = resultScanned.projectId
    if (currentProjectId !== scannedBCProjectId) throw this.$t('scanned_barcode_not_same_project')

    if (!resultScanned.isActivated) throw this.$t('barcode not activated')

    await unpairBarcode({
      parentBarcodeId: this.barcode.id,
      isUnpairAll: false,
      unpairChildrenIds: [resultScanned?.id],
      isDryRun: true,
    })
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.barcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('unpairing_read_result'),
        operation: UNPAIRING,
      },
    })
  }
}
