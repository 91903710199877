<template>
  <div class="edit-view display-in-center view" v-loading.fullscreen="loading || !isReadyToScan">
    <div class="step-label">
      {{ $t('unpairing_barcodes') }}
    </div>
    <div class="barcode-scanner relative">
      <div class="scan-area" v-if="isScanditReaderProject" id="scan-area"></div>
      <div id="scan-area" class="scan-area" v-else>
        <video id="video"></video>
      </div>
      <BarcodeSymbologiesSelection
        class="absolute bottom-2 z-50 left-2 flex w-52"
        @update:onSymbologiesChange="setBarcodeSymbologies"
        v-model:symbologies="symbologies"
      />
      <ScanArea />
    </div>
    <div class="barcode-number-block">
      <span>
        {{ $t('Number of Scanned Barcodes') }}
      </span>
      <span class="label-box" @click="onCheckScannedBarcode">
        {{ scannedBarcodeList.length }}
      </span>
    </div>
    <el-button circle :disabled="scannedBarcodeList.length === 0" type="primary" class="submit-button" @click="forward">
      {{ $t('OK') }}
    </el-button>
  </div>
  <!-- <el-dialog
    :lock-scroll="true"
    center
    v-model="dialogVisible"
    width="90%"
    top="0"
    :show-close="false"
    :destroy-on-close="false"
    :title="checkScannedBarcode ? $t('Scanned Barcodes') : ''"
  >
    <template v-if="checkScannedBarcode">
      <p v-if="scannedBarcodeList.length === 0">
        {{ $t('no barcodes') }}
      </p>
      <BarcodeLabelList v-else :barcodes="scannedBarcodeList || []" />
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onCloseDialog">
          {{ $t('OK') }}
        </el-button>
      </span>
    </template>
  </el-dialog> -->
</template>
<script lang="ts">
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import MultipleBarcodeScanMixin from '@/components/mixins/MultipleBarcodeScanMixin.vue'
import ScanArea from '@/components/svg/ScanArea.vue'
import { FETCH_BARCODE, SAVE_SCANNED_BARCODES, UNPAIRING } from '@/store/actions'
import { unpairBarcode } from '@/utils/api'
import { IBarcode, IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
@Options({
  components: {
    ScanArea,
    BarcodeLabelList,
    BarcodeSymbologiesSelection,
  },
  emits: [],
  name: 'BarcodeUnpairScan',
})
export default class BarcodeUnpairScan extends Mixins(MultipleBarcodeScanMixin) {
  loading = false
  dialogVisible = false
  isReadyToScan = true

  async created() {
    this.loading = true
    await this.getBarcodeInfo()
    this.loading = false
  }

  async getBarcodeInfo() {
    if (this.$route.params?.barcodeId && this.$route.params?.project) {
      await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params?.barcodeId })
    }
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  barcodeTypeInfo(barcodeType: string): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project?.details?.barcodeTypes[barcodeType]
  }

  async validateBarcode(resultScanned: IBarcode) {
    if (this.barcode.id === resultScanned.id) throw this.$t('barcode_unpairing_by_itself_error')

    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = resultScanned.projectId
    if (currentProjectId !== scannedBCProjectId) throw this.$t('scanned_barcode_not_same_project')

    if (!resultScanned.isActivated) throw this.$t('barcode not activated')

    await unpairBarcode({
      parentBarcodeId: this.barcode.id,
      isUnpairAll: false,
      unpairChildrenIds: [resultScanned?.id],
      isDryRun: true,
    })
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.barcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('unpairing_read_result'),
        operation: UNPAIRING,
      },
    })
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.scan-area {
  height: 100%;
}

.edit-view.view {
  padding-left: 0;
  padding-right: 0;
}

.step-label {
  margin-bottom: 25px;
}

.barcode-scanner {
  height: 390px;
  background: rgb(32, 32, 32);
  position: relative;
}

.barcode-scanner video {
  width: 100%;
  height: 100%;
}
</style>
